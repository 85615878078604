import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as MobXProvider } from 'mobx-react';
import LanguageProvider from 'containers/LanguageProvider';
import App from 'containers/App';
import { HOME_DETAIL_PAGE_VISITS } from "constants/localStorage";
import { initStores } from './stores';
import reportWebVitals from './reportWebVitals';
import './index.css';

export const stores = initStores();

ReactDOM.render(
  <React.StrictMode>
    <MobXProvider {...stores}>
      <LanguageProvider>
          <App />
      </LanguageProvider>
    </MobXProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


window.addEventListener('beforeunload', () => {
  localStorage.removeItem(HOME_DETAIL_PAGE_VISITS);
});